/*
 * Base structure
 */

// Variables
@import "variables";

/* Move down content because we have a fixed navbar that is 50px tall */
body {
    padding-top: 90px;
  }
  
  
  /*
   * Global add-ons
   */
  
  .sub-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
  
  /*
   * Top navigation
   * Hide default border to remove 1px line.
   */
  .navbar-fixed-top {
    border: 0;
  }
  .navbar-beepay{
    border-bottom: 1px rgba(#000,0.1) solid;
    padding: 20px;
    font-weight: 300;
    background: white;
  }
  
  /*
   * Sidebar
   */
  
  /* Hide for mobile, show later */
  .sidebar {
    display: none;
  }
  @media (min-width: 768px) {
    .sidebar {
      position: fixed;
      top: 90px;
      bottom: 0;
      left: 0;
      z-index: 1000;
      display: block;
      padding: 20px;
      overflow-x: hidden;
      overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
      background-color: #fff;
      border-right: 1px rgba(#000,0.1) solid;
      font-family: Lato;
      font-weight: 300;
    }
  }
  
  /* Sidebar navigation */
  .nav-sidebar {
    margin-right: -21px; /* 20px padding + 1px border */
    margin-bottom: 20px;
    margin-left: -20px;
  }
  .nav-sidebar > li > a {
    padding-right: 20px;
    padding-left: 20px;
  }
  .nav-sidebar > .active > a,
  .nav-sidebar > .active > a:hover,
  .nav-sidebar > .active > a:focus {
    color: #fff;
    background-color: $brand-primary;
  }
  
  
  /*
   * Main content
   */
  
  .main {
    padding: 40px;
  }
  @media (min-width: 768px) {
    .main {
      padding-right: 40px;
      padding-left: 40px;
    }
  }
  .main .page-header {
    margin-top: 0;
    
  }
  
  
  /*
   * Placeholder dashboard ideas
   */
  
  .placeholders {
    margin-bottom: 30px;
    text-align: center;
  }
  .placeholders h4 {
    margin-bottom: 0;
  }
  .placeholder {
    margin-bottom: 20px;
  }
  .placeholder img {
    display: inline-block;
    border-radius: 50%;
  }

.panel{
  border:none;
}
  .panel-heading{
    padding: 25px 25px;
  }
  .panel-title{
    display: inline-block;
    font-size: 18px;
    line-height: 1.7;
  }
  .table{
    font-family: lato;
    font-weight: 300;
    font-size: 1.3rem;
}
  